import React, { useState, useContext } from 'react';
import AppContext from '../../context/AppContext';
import { images } from '../../constants';
import './PricePack.scss';

// Припустимо, що маємо таку структуру даних для пакетів
const servicePackages = {
      mechanical: [
        { 
        id: 1, 
        name: 'Теоретичний курс', 
        cost: '1 999 грн', 
        descriptionItems: [
            'Теоретичні заняття з ПДР (ОФЛАЙН, ОНЛАЙН-платформа та ZOOM- формат)',
            'Заняття з медицини',
            'Щотижневий вебінар',
            'Доступ до всіх бонусних файлів, відео та посилань',
            'Реєстрація на теоретичний курс в системі ТСЦ МВС',
            'Повний супровід автошколою до отримання П/В',
            'Без додаткових та прихованих платежів'
        ],
        image: images.pack1
        },
        { 
        id: 2, 
        name: 'Практичний курс', 
        cost: '33 999 грн', 
        descriptionItems: [
            '40 практичних занять  (850 грн /60 хв)',
            'Бензин та паливно-мастильні матеріали',
            'Реєстрація в системі ТСЦ МВС',
            'Повний супровід автошколою до отримання П/В',
            'Внутрішній іспит з практичної підготовки',
            'Оплата частинами',
            'Подача автомобіля для складання іспиту до ТСЦ МВС',
            'Без додаткових та прихованих платежів',
        ],
        image: images.pack2
        },
        { 
        id: 3, 
        name: 'Експерт пакет', 
        cost: '34 999 грн', 
        descriptionItems: [
            'Теоретичні заняття з ПДР (ОФЛАЙН, ОНЛАЙН-платформа та ZOOM- формат)',
            'Заняття з медицини',
            'Бензин та паливно-мастильні матеріали',
            '40 практичних занять по 60 хв',
            'Реєстрація на теоретичну та практичну частину в системі ТСЦ МВС',
            'Доступ до всіх бонусних файлів, відео та посилань',
            'Внутрішній іспит з практичної підготовки',
            'Повний супровід автошколою  до отримання П/В',
            'Щотижневий вебінар',
            '«Заморожування» навчання',
            'Членство в клубі TAURUS Club',
            'Індивідуальна знижка на послуги TAURUS Car',
            'Оплата частинами',
            'Подача автомобіля для складання іспиту до ТСЦ МВС',
            'Без додаткових та прихованих платежів'
        ],
        image: images.pack3
        },
      ],
      automatic: [
        { 
          id: 1, 
          name: 'Теоретичний курс', 
          cost: '1 999 грн', 
          descriptionItems: [
            'Теоретичні заняття з ПДР (ОФЛАЙН, ОНЛАЙН-платформа та ZOOM- формат)',
            'Заняття з медицини',
            'Щотижневий вебінар',
            'Доступ до всіх бонусних файлів, відео та посилань',
            'Реєстрація на теоретичний курс в системі ТСЦ МВС',
            'Повний супровід автошколою до отримання П/В',
            'Без додаткових та прихованих платежів'
          ],
          image: images.pack1
        },
        { 
          id: 2, 
          name: 'Практичний курс', 
          cost: '37 999 грн', 
          descriptionItems: [
            '40 практичних занять  (950 грн /60 хв)',
            'Бензин та паливно-мастильні матеріали',
            'Реєстрація в системі ТСЦ МВС',
            'Повний супровід автошколою до отримання П/В',
            'Внутрішній іспит з практичної підготовки',
            'Оплата частинами',
            'Подача автомобіля для складання іспиту до ТСЦ МВС',
            'Без додаткових та прихованих платежів'
          ],
          image: images.pack2
        },
        { 
          id: 3, 
          name: 'Експерт пакет', 
          cost: '38 999 грн', 
          descriptionItems: [
            'Теоретичні заняття з ПДР (ОФЛАЙН, ОНЛАЙН-платформа та ZOOM- формат)',
            'Заняття з медицини',
            'Бензин та паливно-мастильні матеріали',
            '40 практичних занять по 60 хв',
            'Реєстрація на теоретичну та практичну частину в системі ТСЦ МВС',
            'Доступ до всіх бонусних файлів, відео та посилань',
            'Внутрішній іспит з практичної підготовки',
            'Повний супровід автошколою  до отримання П/В',
            'Щотижневий вебінар',
            '«Заморожування» навчання',
            'Членство в клубі TAURUS Club',
            'Індивідуальна знижка на послуги TAURUS Car',
            'Оплата частинами',
            'Подача автомобіля для складання іспиту до ТСЦ МВС',
            'Без додаткових та прихованих платежів'
          ],
          image: images.pack3
        },
      ],
};

const PricePack = () => {
  const [activeTab, setActiveTab] = useState('mechanical');
  const { toggleVisibility } = useContext(AppContext);

  return (
    <div className='app__priceTab' name='prices'>
      <div className='container'>
        <div className="title-section text-center text-uppercase">Наші тарифи</div>
        <div className="btn-container d-flex">
          <div className="tab-buttons">
            <button 
              className={`btn btn-gradient transmissionButton ${activeTab === 'automatic' ? 'active' : ''}`} 
              onClick={() => setActiveTab('automatic')}
            >
              Автомат
            </button>
            <button 
              className={`btn btn-gradient transmissionButton ${activeTab === 'mechanical' ? 'active' : ''}`}
              onClick={() => setActiveTab('mechanical')}
            >
              Механіка
            </button>
          </div>
          <div>
            <img className='checkImg' alt='Check' src={images.check} />
          </div>
          <div>
            <p className="garant">100% гарантія повернення грошей <br/> якщо вам не сподобається наше навчання</p>
          </div>
        </div>
        <div className='tabs__body'>
          <div className="tab-content">
            {servicePackages[activeTab].map((pkg) => (
              <div key={pkg.id} className="package-container"> {/* Додатковий контейнер */}
                <div className={`package ${pkg.name === 'Практичний курс' ? 'package--large-screen' : ''}`}>
                  {pkg.name === 'Експерт пакет' && (
                    <span className="package__discount"><span>Економія <b>1 000 грн</b></span></span>
                  )}
                  <h3>{pkg.name}</h3>
                  <ul>
                    {pkg.descriptionItems.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                  <div className='package__img-container'>
                    <img className='package__img' src={pkg.image} alt={pkg.name} />
                  </div>
                </div>
                <button onClick={toggleVisibility} className='btn btn-buy'>Купити за {pkg.cost}</button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricePack;
